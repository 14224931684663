<template>
    <div class="testing">
        <img class="bannerImg" src="../assets/images/bananr-gongsi.png" alt="">

        <div class="panorama">
            <p class="panoramaTitle">工业视觉检测方案</p>
            <img style="margin-top: 0.5rem;" class="bannerImg" src="../assets/images/solution/banner.png" alt="">
              <div class="banner">
                <p class="title">人工智能+在线教育：</p>
                <p class="reform">我们是教育行业的变革者</p>
                <p class="tip">Be The Best Adaptive Learning Platform</p>
            </div>
        </div>
        <p class="panoramaTitle">方案简介</p>
        <div class="page_wrap" style="margin:0.5rem auto;">
            <div class="page_content" style="maxWidth:15rem">
                <div v-for="(item, index) in photoList" :key="index">
                    <div class="newsList-item news-flex">
                        <div class="newsList-item-left background" :style="{ 'backgroundImage': `url(${item.src})`}">
                        </div>
                        <div class="newsList-item-right">
                            <div class="newsItem-content">
                                {{ item.content }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="detail">
            <p style="font-size: 0.24rem;padding-bottom: 0.2rem;">解决方案介绍</p>
            <!--height: 4rem;-->
            <img style="width: 6.8rem;" src="../assets/images/industry.png" alt="">
            <div class="text">
                2020年受疫情影响，我国制造业PMI起伏较大。2020年第四季度后，制造业复苏步伐有所加快，制造业总体保持稳步恢复的良好势头。2021年上半年，制造业PMI稳定至荣枯线以上并有持续上升趋势。

                从国家层面来看，党中央、国务院及各相关部门持续高度重视制造业的发展，国家“十四五”规划已经明确提出要培育先进制造业集群，要提升传统产业，要应用技术改造专项，鼓励企业应用先进的适用性技术，而工业机器视觉就是一种先进的适用性技术；在工业互联网创新发展行动计划中也明确提出了要支持新兴技术与自动化企业打造边缘控制系统。

                从促进产业转型升级方面来看，人工智能将会促进城市产业结构转型升级调整。当前的人工智能尚处于与大数据、商业融合的初始阶段，未来十年人工智能将会穿透各产业，促进以制造业为主的第二产业转型升级。随着自然语言识别、计算机视觉、机器智能等技术突破，人工智能产品在感知、识别、判断等方面能力的提升，人工智能产品将会赋能传统产业，对传统产业进行智能化改造，提升生产效率；同时无人驾驶汽车、智能机器人、智能家居等人工智能产品不断上市，推动人工智能产业向高端产业价值链发展，促进城市产业结构进一步转型升级。

            </div>
        </div>
        <div class="income">
            <p class="panoramaTitle">客户收益</p>
            <div class="page_wrap" style="margin:0.5rem auto;">
                <div class="page_content" style="maxWidth:15rem">
                    <div v-for="(item, index) in incomeList" :key="index">
                        <div class="newsList-item news-flex">
                            <div class="newsList-item-left background">
                                {{item.num}}
                            </div>
                            <div class="newsList-item-right">
                                <div class="newsItem-title news-flex">
                                    <span style="color: #196ECE;margin-bottom: 0.2rem;">{{ item.name }}</span>
                                </div>
                                <div class="newsItem-content">
                                    {{ item.content }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p class="panoramaTitle" style="marginBottom:0.5rem">方案演示</p>
        <div class="page_wrap background" :style="{ 'backgroundImage': `url(${require('@/assets/images/solution/2.png')})`, 'height': '5.2rem', 'position': 'relative'}">
            <el-button type="primary" class="algoBtn" @click="$router.push('/algo')">体验一下<i class="el-icon-right"></i></el-button>
            <div class="background-bottom">
                <div class="background-bottom-content">
                    <div class="background-bottom-content-item" v-for="item in algoList" :key="item.index">
                            {{ item.label }}
                    </div>
                </div>
            </div>
        </div>

        <div class="customerCase" v-if="false">
            <p class="panoramaTitle">客户案例</p>
            <div class="page_wrap" style="margin:0.5rem auto;">
                <div class="page_content" style="maxWidth:15rem">
                    <div v-for="(item, index) in caseList" :key="index">
                        <div class="newsList-item news-flex">
                            <div class="newsList-item-left background" :style="{ 'backgroundImage': `url(${item.src})`}">
                            </div>
                            <div class="newsList-item-right">
                                <div class="newsItem-title news-flex">
                                    <span>{{ item.name }}</span>
                                </div>
                                <div class="newsItem-content">
                                    {{ item.content }}<br>
                                    工作流程：破碎处理--->碎石清洗--->抽样拍照--->视觉分析--->触发告警--->停机检修
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="partner">
            <p class="panoramaTitle">合作伙伴</p>
            <p class="partnerTitle">我们将与众多同行者一起共建人工智能产业生态</p>
            <div class="partnerList">
                <img v-for="(item, index) in imglist" :key="index"  :src="item" style="margin: 0 0.5rem;">
            </div>
        </div>
    </div>
</template>
<script>
    /* eslint-disable */
    import newsData from "@/assets/js/news";

    export default {
        name: 'solution',
        data () {
            return {
                algoList:[
                    { label:'入侵检测', url:'' },
                    { label:'姿态估计', url:'' },
                    { label:'钢筋计数', url:'' },
                    { label:'安全帽检测', url:'' },
                    { label:'车牌识别', url:'' },
                    { label:'火焰检测', url:'' },
                    { label:'翻墙检测', url:'' },
                    { label:'口罩检测', url:'' },
                ],
                photoList: [{name: '智算云平台',
                    src: require('@/assets/images/industry2.png'),
                    content: '中科逆熵工业视觉检测以打造智慧企业为目标，以多种人工智能算法和打数据处理为手段，依托“云边一体”的架构基础，结合人工智能、数字孪生等新一代信息技术，自上而下打通战略决策层到现场执行层的各个系统，提高生产效率，实现个性化定制、网络化协同、智能化制造、服务化延伸的新型制造管理模式，为制造企业数字化转型升级赋能。',
                },],
                caseList: [{name: '工业视觉—智能矿石检测',
                    src: require('@/assets/images/solution/case.png'),
                    content: '传统的生产流水线上产品下线后大多有一个目检环节，即通过人眼检查发现产品表面的缺陷和瑕疵，人工目检效率低、容易漏检，并且人工成本也越来越高，智能云边一体开放平台通过视觉分析算法模型极大提高了检测效率。'
                }],
                incomeList: [{name: '数字化转型升级',
                    num: '01',
                    content: '有利于制造业的数字化转型升级，运用多种人工智能深度学习检测手段可大大减少机器对人的伤害，长远来看促进了生产力的变革，改善了劳动者的工作条件，提高了企业的数字化水平，为国家发展高端制造业建立了一个高技术力的新局面。'
                },{name: '提供有力的信息保障',
                    num: '02',
                    content: '制造业作为我国国民经济的支柱产业，是经济增长的主导部门和经济转型的基础，更是经济社会发展的重要依托。工业视觉检测智能处理解决方案面向大、中、小型各类制造业企业，能够提供面向工厂实际生产层面的关键应用，满足不同层次的检测需求。为制造行业企业的发展提供有力的信息保障。'
                },{name: '提升业务稳定性',
                    num: '03',
                    content: '采用边缘端算法识别，硬件架构更简单，更稳定，性价比高，能够显著降低效费比；更具有硬件快速部署，算法灵活交付的特点，缩短业务上线周期，提高产品质量管理效率。'
                }],
                productChildren: [],
                imglist:[
                    require('@/assets/images/partner/1.png'),require('@/assets/images/partner/2.png'),require('@/assets/images/partner/3.png'),
                    require('@/assets/images/partner/4.png'),require('@/assets/images/partner/5.png'),require('@/assets/images/partner/6.png'),
                    require('@/assets/images/partner/7.png'),require('@/assets/images/partner/8.png'),require('@/assets/images/partner/9.png'),
                    require('@/assets/images/partner/10.png'),require('@/assets/images/partner/11.png'),require('@/assets/images/partner/12.png'),
                    require('@/assets/images/partner/13.png'),require('@/assets/images/partner/14.png'),require('@/assets/images/partner/15.png'),
                    require('@/assets/images/partner/16.png'),require('@/assets/images/partner/17.png'),require('@/assets/images/partner/18.png'),
                    require('@/assets/images/partner/19.png'),require('@/assets/images/partner/20.png'),require('@/assets/images/partner/21.png'),
                ],
            }
        },
        mounted () {
            this.productChildren = newsData.solutionList
            document.body.scrollTop = document.documentElement.scrollTop = 0
        },
        methods: {
            toRoute (val) {
                this.$router.push(val)
            }
        }
    }
</script>
<style lang="scss" scoped>
.panorama{
    position: relative;
}
.banner{
    top:65%;
    // right:20%;
    .title,.reform{
        font-size: 0.4rem;
        font-weight: 600;
        margin-top: 0.2rem;
        font-style: italic;
    }
    p:nth-child(2){
        line-height: 0.5rem;
    }
    .tip{
      font-size: 0.3rem;
      margin-top: 0.5rem;
    }

}
    .algoBtn{
        position: absolute;
        bottom:0.8rem;
        right:10%;
    }
    .background-bottom{
        position:absolute;
        width: 100%;
        bottom:0;
        left:0;
        background: #ffffff7c;
        height:0.5rem;
    }
    .background-bottom-content{
        width: 70%;
        height: 0.5rem;
        line-height: 0.5rem;
        display: flex;
        justify-content: space-between;
        margin:0 auto;
        &-item{
            width: 0.8rem;
            height: 0.5rem;
            color:#606266;
            cursor: pointer;
            font-size: 0.14rem;
        }
        &-item:hover{
            color:#0061E3;
            border-bottom:1px solid #0061E3;
        }
    }
    .testing {
    @keyframes partnerAnima
    {
        0%   {right: 0}
        50%  {right: 30rem}
        100%  {right: 60rem}
    }
    .partnerList {
        position: relative;
        animation: partnerAnima 100s linear 1 alternate;
    }
    width: 100%;
    height: 100%;
    text-align: center;
    .detail {
        background: #F7F7F7;
        width: 80%;
        margin: 0.5rem auto;
        padding: 0.2rem;
        .text {
            text-align: left;
            width: 60%;
            margin: 0.2rem auto;
            color: #333333;
            font-size: 0.14rem;
        }
    }
    .partner {
        overflow: hidden;
        width: 80%;
        margin: 0 auto;
    .partnerList {
        height: 1.2rem;
        width: 61rem;
    img {
        height: 1.2rem;
        float: left;
    }
    }
    .partnerTitle {
        color: #999999;
        font-size: 0.16rem;
        margin: 0.2rem 0;
    }
    }
    .panoramaTitle {
        text-align: center;
        font-size: 0.28rem;
        margin-top: 0.6rem;
        // background-color: #012961;
        color: #333;
        border-radius: 0.2rem;
        display: inline-block;
        padding: 0.1rem 0.2rem;
    }
    .panorama {
        text-align: center;
        width: 80%;
        margin: 0 auto;
    .productTab {
        width:100%;
        height: 4.5rem;
        margin: 0.5rem auto auto auto;
    .productChildren {
    //margin-bottom: 0.4rem;
        height: 3rem;
    .left, .right {
        display: inline-block;
        width: 20%;
        height: 100%;
        background-color: #F7F7F7;
        border-top: 2px solid #0061E3;
        text-align: left;
        position: relative;
        box-shadow: 0px 3px 8px 0px rgba(187, 187, 187, 0.3);
    .text {
        display: inline-block;
        width: 90%;
        height: 100%;
        overflow: hidden;
        padding: 0.2rem;
    div {
        padding: 0.2rem;
        font-size: 0.16rem;
    span {
        padding: 0.1rem;
        cursor: pointer;
    }
    .active {
        border-bottom: 1px solid #0061E3;
        color: #0061E3;
    }
    }
    }
    }
    .right {
        margin-left: 5%;
    }
    }
    .application {
        height: 4rem;
        overflow: hidden;
    }
    .tabs {
        margin-bottom: 0.4rem;
    span {
        display: inline-block;
        width: 20%;
        height: 0.64rem;
        border-radius: 0.03rem;
        background: #F7F7F7;
        cursor: pointer;
        color: #0061E3;
        line-height: 0.6rem;
        font-size: 0.24rem;
    }
    .active {
        background: #0061E3;
        color: #FFFFFF;
    }
    }
    }
    }
    }
    .income {
        width: 70%;
        margin: 0 auto;
        .newsList-item {
            height: 1rem;
            &-left{
                 width:2.4rem;
                 height: 1rem;
                 color: #196ECE;
                 font-weight: 700;
                 font-size: 0.32rem;
                line-height: 1rem;
                text-align: center;
                border-right: 2px solid #196ECE;
             }
            &-right {
                 width: calc(100% - 2.8rem);
             }
            }
        .newsItem-content {
            height: 0.5rem;
        }
    }
    .customerCase {
        .newsList-item {
        &-left{
             width:4.4rem;
             height: 2.8rem;
         }
        &-right {
             width: calc(100% - 4.8rem);
         }
        }
    }
    .news-flex{
        display: flex;
        justify-content: space-between;
    }
    .newsList-item{
        height: 3rem;
        padding:0.2rem 0.1rem;
        border-top:1px solid #dedede;
        border-bottom:1px solid #dedede;
        text-align: left;
    &-left{
         width:7.4rem;
         height: 2.8rem;
     }
    &-right{
         width: calc(100% - 7.8rem);
         display: flex;
         justify-content: space-around;
         flex-direction: column;
    .newsItem-title{
        text-align: left;
        font-size: 0.22rem;
        color:#333;
    }
    }
    .newsItem-content{
        font-size: 0.13rem;
        color:#606266;
        line-height: 0.26rem;
        height:1.5rem;
        width:100%;
        overflow:hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        /* autoprefixer: off */
        -webkit-box-orient:vertical;
        /* autoprefixer: on */
        -webkit-line-clamp:6;

    }
    .newsItem-link{
        width: 100%;
        height:0.4rem;
    &-button{
         width: 0.3rem;
         height:0.3rem;
         float: right;
     }
    }

    }

    .newsList-item:hover {
        background: #f0f6ff;
        cursor: pointer;
    }

</style>
